import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Advanced_User/OpenHAB_3_in_Docker/PrimaryBox';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHAB 3 Home Automation in Docker",
  "path": "/Advanced_User/OpenHAB_3_in_Docker/",
  "dateChanged": "2020-12-30",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_OpenHAB.png",
  "social": "/images/Search/AU_SearchThumb_OpenHAB.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='OpenHAB 3 Home Automation in Docker' dateChanged='2020-12-30' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_OpenHAB.png' twitter='/images/Search/AU_SearchThumb_OpenHAB.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/OpenHAB_3_in_Docker/' locationFR='/fr/Advanced_User/OpenHAB_3_in_Docker/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "openhab-3-home-automation-in-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-3-home-automation-in-docker",
        "aria-label": "openhab 3 home automation in docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB 3 Home Automation in Docker`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#pulling-the-docker-image"
        }}>{`Pulling the Docker Image`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#preparing-the-system"
        }}>{`Preparing the System`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#running-the-container"
        }}>{`Running the Container`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#automating-docker-setup-using-ansible"
        }}>{`Automating Docker setup using Ansible`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#maintenance"
        }}>{`Maintenance`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#clean-up-when-things-go-wrong"
            }}>{`Clean Up when things go wrong`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#openhab-cli"
            }}>{`OpenHAB CLI`}</a></li>
        </ul>
      </li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "pulling-the-docker-image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#pulling-the-docker-image",
        "aria-label": "pulling the docker image permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pulling the Docker Image`}</h2>
    <p>{`The OpenHAB 3 image is available on `}<a parentName="p" {...{
        "href": "https://hub.docker.com/r/openhab/openhab/tags?page=1&ordering=last_updated"
      }}>{`Docker Hub`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker pull openhab/openhab:3.1.0-snapshot-alpine`}</code></pre></div>
    <h2 {...{
      "id": "preparing-the-system",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#preparing-the-system",
        "aria-label": "preparing the system permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Preparing the System`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`groupadd`}</span>{` -g `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`useradd`}</span>{` -g `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`usermod`}</span>{` -a -G openhab myownuser`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/openhab/`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`openhab_addons,openhab_conf,openhab_userdata`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` -R `}<span parentName="code" {...{
            "className": "token number"
          }}>{`775`}</span>{` /opt/openhab`}</code></pre></div>
    <h2 {...{
      "id": "running-the-container",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#running-the-container",
        "aria-label": "running the container permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Running the Container`}</h2>
    <p>{`From your commandline:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
        --name openhab `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
        --net`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`host `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
        --privileged `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
         --rm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
        -v /etc/localtime:/etc/localtime:ro `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
        -v /opt/openhab/openhab_addons:/openhab/addons `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
        -v /opt/openhab/openhab_conf:/openhab/conf `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
        -v /opt/openhab/openhab_userdata:/openhab/userdata `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
        -d `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
        openhab/openhab:3.1.0-snapshot-alpine`}</code></pre></div>
    <p>{`In `}<strong parentName="p">{`docker-compose`}</strong>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`version: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"3.8"`}</span>{`

services:
  openhab:
    image: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"openhab/openhab:3.1.0-snapshot-alpine"`}</span>{`
    restart: always
    network_mode: `}<span parentName="code" {...{
            "className": "token function"
          }}>{`host`}</span>{`
    volumes:
      - `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/etc/localtime:/etc/localtime:ro"`}</span>{`
      - `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/etc/timezone:/etc/timezone:ro"`}</span>{`
      - `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/opt/openhab/openhab_addons:/openhab/addons"`}</span>{`
      - `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/opt/openhab/openhab_conf:/openhab/conf"`}</span>{`
      - `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/opt/openhab/openhab_userdata:/openhab/userdata"`}</span>{`
    environment:
      OPENHAB_HTTP_PORT: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"8181"`}</span>{`
      OPENHAB_HTTPS_PORT: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"8443"`}</span>{`
      EXTRA_JAVA_OPTS: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-Duser.timezone=Europe/Berlin"`}</span></code></pre></div>
    <blockquote>
      <p parentName="blockquote">{`I changed the HTTP port to 8181 - as I am already using the default port 8080 for a different service. Make sure the port is accessible from your LAN: `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`ufw allow 8181/tcp`}</code></p>
    </blockquote>
    <h2 {...{
      "id": "automating-docker-setup-using-ansible",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#automating-docker-setup-using-ansible",
        "aria-label": "automating docker setup using ansible permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Automating Docker setup using Ansible`}</h2>
    <p>{`You can test it by running:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`ansible-playbook -i mycontainerhost, -t openhab run-containers.yml`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` OpenHAB v3 Container
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`hosts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` test
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`gather_facts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` no
  
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`tasks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`

  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Run OpenHab Container
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`tags`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` openhab
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`docker_container`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` openhab
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` openhab/openhab`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`3.1.0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`snapshot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`alpine
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` started
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`detach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` yes
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`interactive`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` yes
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`tty`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` yes
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 8181`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 8101`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8101`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 5007`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5007`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /etc/localtime`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/etc/localtime`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`ro
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /etc/timezone`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/etc/timezone`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`ro
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /opt/openhab/addons`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/openhab/addons
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /opt/openhab/conf`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/openhab/conf
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /opt/openhab/userdata`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/openhab/userdata
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`keep_volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` yes
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`hostname`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` openhab.localnet
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`memory`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 512m
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`pull`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart_policy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` unless`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`stopped
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`env`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        EXTRA_JAVA_OPTS="`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`Duser.timezone=Europe/Berlin"`}</code></pre></div>
    <p>{`Run the playbook and visit the IP address of your server on port `}<strong parentName="p">{`8181`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "790px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2959d25e18e5910ab58e2b9c00e7a277/2e237/OpenHAB3_Docker_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABQUlEQVQoz5WRz0/CMBTH54EYSXB777Vdu62hA6KTDZgCDtFoiAeNN2K8aPTmwb/Asxfj3aP/rClDQ0AjfvLSJu33k9cfzqAYD0fjw9E4P+j38n7WyfvDotvbb6fddtopazdJk72snfVOi+JsMrm5fri4nI6OTxxEQiTGGDFGREgWQHQRt22RawMIAETEZrvfk4MzAGBhRCV4ooMsYEUAWcjDKArDsMzZ0BdzeREPQDHa1SpVdGRk10TGGK2153lLyWUZADjnJo61ievNlm60orqpG2NXtC6P9quMaG+toyhQMghUoCxSSqWU7/t/dC6JGw0h+FKfVX6WpZSMzd90kf/IXMyr/MU1OwshGJG3ueFWHLfiYK1KjK0rA3hAPJ4+J7cvO/dvfn4OtSoSW0u2P8ZE8+618/iePn3IwRXUtlblT8p5RJSC9uIAAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2959d25e18e5910ab58e2b9c00e7a277/e4706/OpenHAB3_Docker_01.avif 230w", "/en/static/2959d25e18e5910ab58e2b9c00e7a277/d1af7/OpenHAB3_Docker_01.avif 460w", "/en/static/2959d25e18e5910ab58e2b9c00e7a277/0db0f/OpenHAB3_Docker_01.avif 790w"],
              "sizes": "(max-width: 790px) 100vw, 790px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2959d25e18e5910ab58e2b9c00e7a277/a0b58/OpenHAB3_Docker_01.webp 230w", "/en/static/2959d25e18e5910ab58e2b9c00e7a277/bc10c/OpenHAB3_Docker_01.webp 460w", "/en/static/2959d25e18e5910ab58e2b9c00e7a277/02aff/OpenHAB3_Docker_01.webp 790w"],
              "sizes": "(max-width: 790px) 100vw, 790px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2959d25e18e5910ab58e2b9c00e7a277/81c8e/OpenHAB3_Docker_01.png 230w", "/en/static/2959d25e18e5910ab58e2b9c00e7a277/08a84/OpenHAB3_Docker_01.png 460w", "/en/static/2959d25e18e5910ab58e2b9c00e7a277/2e237/OpenHAB3_Docker_01.png 790w"],
              "sizes": "(max-width: 790px) 100vw, 790px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2959d25e18e5910ab58e2b9c00e7a277/2e237/OpenHAB3_Docker_01.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose the bindings that you will need to be installed:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "886px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a08acf1e664fcdc74d71fb9a5a7871b8/cc155/OpenHAB3_Docker_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABZklEQVQoz42SzW7bMBCEc29s7Q9JSZRIyooFWBJpSUkQtw0Kv0ov+T2lb9NrEqPPWcRuAduB4wzmsADxYWYXPKEdMbH4N7FgjN7MTAd0cughOv0Ctsayj8Yj/CzMTIhSSmfy0l+WzWBMLqVExOMwIEYAUqlhOL8YuvO+894bYxDxff8dWAgRvO/m8xB88G3btj68qSgKXuvDZGZTVraszOTMTWe2OCucs9Yeutk+TLGmOCWZoMpQKACAdeFPJBNBNB6PRhKhSchJtIqdYsWER5MRsaqqru+11opQMm28SZRSfgQDQNu2y+VyOp0CAAvBW4rjeK/5fvJsVn/7fu1DQCIkXps2Vio5Auc6Ll1e5GlhdGH0xGaboXS5y1Pa3X3vYKyqIW0Wab1I6ys7v3b9j6S+0s3XtFnEs0uSCW19tS0YgWSc/fydPfzRty/2/rX7tQpPq8nja3r7kt2t9M2zKANFI/pf/i8jKFJg7pd1tAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a08acf1e664fcdc74d71fb9a5a7871b8/e4706/OpenHAB3_Docker_02.avif 230w", "/en/static/a08acf1e664fcdc74d71fb9a5a7871b8/d1af7/OpenHAB3_Docker_02.avif 460w", "/en/static/a08acf1e664fcdc74d71fb9a5a7871b8/d297b/OpenHAB3_Docker_02.avif 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a08acf1e664fcdc74d71fb9a5a7871b8/a0b58/OpenHAB3_Docker_02.webp 230w", "/en/static/a08acf1e664fcdc74d71fb9a5a7871b8/bc10c/OpenHAB3_Docker_02.webp 460w", "/en/static/a08acf1e664fcdc74d71fb9a5a7871b8/b4685/OpenHAB3_Docker_02.webp 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a08acf1e664fcdc74d71fb9a5a7871b8/81c8e/OpenHAB3_Docker_02.png 230w", "/en/static/a08acf1e664fcdc74d71fb9a5a7871b8/08a84/OpenHAB3_Docker_02.png 460w", "/en/static/a08acf1e664fcdc74d71fb9a5a7871b8/cc155/OpenHAB3_Docker_02.png 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a08acf1e664fcdc74d71fb9a5a7871b8/cc155/OpenHAB3_Docker_02.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "maintenance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#maintenance",
        "aria-label": "maintenance permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Maintenance`}</h2>
    <h3 {...{
      "id": "clean-up-when-things-go-wrong",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#clean-up-when-things-go-wrong",
        "aria-label": "clean up when things go wrong permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Clean Up when things go wrong`}</h3>
    <p>{`Delete the contents of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt/openhab/userdata/cache`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt/openhab/userdata/tmp`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` -rf /opt/openhab/userdata/cache
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` -rf /opt/openhab/userdata/tmp`}</code></pre></div>
    <h3 {...{
      "id": "openhab-cli",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#openhab-cli",
        "aria-label": "openhab cli permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB CLI`}</h3>
    <p>{`Access the OpenHAB command line tool inside the Docker container from your host system:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -it openhab /openhab/runtime/bin/client

Logging `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` as openhab
Password:  PASSWORD IS habopen

                           _   _     _     ____  
   ___   ___   ___   ___  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`   / `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` __ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
  / _ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{` / _ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{` / _ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{` / _ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`_`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`  / _ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`  _ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{` 
 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`_`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`_`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`  __/`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{`  _  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` / ___ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`_`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`___/`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`  __/ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`___/`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`_`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`_`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{`_`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`_`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`/_/   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`_`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`____/ 
       `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`_`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`       `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3.1`}</span>{`.0-SNAPSHOT - Build `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#2099`}</span>{`

Use `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'<tab>'`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` a list of available commands
and `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'[cmd] --help'`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`help`}</span>{` on a specific command.
To exit, use `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'<ctrl-d>'`}</span>{` or `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'logout'`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{`

openhab`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}</code></pre></div>
    <p>{`Accessing the OpenHab logs from the CLI:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openhab`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` log:tail`}</code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      